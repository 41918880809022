import {
  Box, Container
} from '@chakra-ui/react';
import React from 'react';
import Terminal, { ColorMode, TerminalOutput } from 'react-terminal-ui';
import './App.css';

const TerminalController = (props = {}) => {
  const [terminalLineData, setTerminalLineData] = React.useState([
    <TerminalOutput>datum.sh is coming soon</TerminalOutput>
  ]);

  const handleInput = (terminalInput) => {
    if (terminalInput === 'datum.sh' || terminalInput === 'datum') {
      setTerminalLineData([
        <TerminalOutput>datum.sh is help you manage and share your datasets</TerminalOutput>,
        <TerminalOutput>with other developers.</TerminalOutput>,
        <TerminalOutput></TerminalOutput>,
        <TerminalOutput>More details coming soon...</TerminalOutput>,
      ])
    } else if (terminalInput === 'clear') {
      setTerminalLineData([
        <TerminalOutput>datum.sh is coming soon</TerminalOutput>
      ])
    } else if (terminalInput === 'help') {
      setTerminalLineData([
        <TerminalOutput>Usage:</TerminalOutput>,
        <TerminalOutput>  datum.sh  -  manage your datasets </TerminalOutput>,
        <TerminalOutput>  help      -  show this message</TerminalOutput>,
        <TerminalOutput>  clear     -  clear screen</TerminalOutput>
      ])
    } else if (terminalInput === '') {
      setTerminalLineData([
        <TerminalOutput>Usage:</TerminalOutput>,
        <TerminalOutput>  datum.sh  -  manage your datasets </TerminalOutput>,
        <TerminalOutput>  help      -  show help</TerminalOutput>,
        <TerminalOutput>  clear     -  clear screen</TerminalOutput>
      ])
    } else {
      setTerminalLineData([
        <TerminalOutput>{terminalInput}: command not recognized</TerminalOutput>,
        <TerminalOutput>Usage:</TerminalOutput>,
        <TerminalOutput>  datum.sh  -  manage your datasets </TerminalOutput>,
        <TerminalOutput>  help      -  show help</TerminalOutput>,
        <TerminalOutput>  clear     -  clear screen</TerminalOutput>

      ])
    }
  }

  return (
    <div className="container">
      <Terminal
        name='Loading datum.sh...'
        colorMode={ColorMode.Light}
        onInput={handleInput}>
        {terminalLineData}
      </Terminal>
    </div>
  )
};


function App() {
  return (
    <Box
      bgGradient={{ sm: 'linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)' }}
      py={{ base: '12', md: '24' }}
      minH="100vh"
    >
      <Container
        maxW="3xl"
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
      >
        <TerminalController />

      </Container>
    </Box>
  );
}

export default App;
